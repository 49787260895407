<template>
  <div>
    <label class="settings-title">
      {{ $t('components.form.marketing.campaign.title') }}
    </label>
    <b-row class="col-form-label-sm">
      <b-col cols="12">
        <label class="field-name-class">
          {{ $t('components.form.marketing.campaign.name.label') }}
        </label>
      </b-col>
      <b-col cols="12">
        <d-text-field
            v-model="emailCampaign.name"
            :rules="validation.name"
            size="sm"
            class-name="background-light-blue-inputs"
            error-text="validation.text-input-three-characters"
            place-holder="components.form.marketing.campaign.name.place-holder"
        />
      </b-col>
    </b-row>
    <b-row  v-if="$route.name === 'marketing_email_new' || $route.name === 'marketing_email_update' || custom === 'custom'" class="col-form-label-sm">
      <b-col v-if="isSmsCampaign === false" cols="12">
        <label class="field-name-class">
          {{ $t('components.form.marketing.campaign.object.label') }}
        </label>
      </b-col>
      <b-col v-if="isSmsCampaign === false" cols="12">
        <d-text-field
            v-model="emailCampaign.subject"
            size="sm"
            :rules="validation.subject"
            class-name="background-light-blue-inputs"
            error-text="validation.text-input-three-characters"
            place-holder="components.form.marketing.campaign.name.place-holder"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="7" align="left">
        <label
            :class="sentDate ? 'field-name-class' : 'field-name-class-unselect'"
        >
          {{ $t('components.form.marketing.campaign.set-sending-date') }}
        </label>
      </b-col>
      <b-col align="right">
        <switch-btn
            v-model="sentDate"
            color="blue"
        />
      </b-col>
    </b-row>
    <template v-if="sentDate">
      <b-row>
        <b-col cols="6" align="left">
          <label class="field-name-class">
            {{ $t('components.form.marketing.campaign.sending-date') }}
          </label>
        </b-col>
        <b-col cols="6" align="left">
          <label class="field-name-class">
            {{ $t('components.form.marketing.campaign.sending-hour') }}
          </label>
        </b-col>
      </b-row>
      <b-row class="pt-0">
        <b-col cols="6" align="left">
          <div
              class="has-calendar"
              @click="displayCalendar = !displayCalendar"
          >
            <span class="fa clock-class fa-calendar form-control-feedback"/>
            <b-form-input
                :value="selectedDateFormat"
                size="sm"
                class="background-light-blue-inputs pointer"
            />
          </div>
        </b-col>
        <b-col cols="6" align="left">
          <vue-timepicker
              v-model="selectedHour"
              input-class="background-light-blue-inputs pointer"
          >
            <template v-slot:icon>
              <i class="clock-class fa fa-clock-o"></i>
            </template>
            <template v-slot:clearButton>
              <span class="fa fa-close"></span>
            </template>
          </vue-timepicker>
        </b-col>
      </b-row>
    </template>
    <d-calendar-modal
        :show="displayCalendar"
        :filter-from-date="fromDate"
        @on:calendar-change="onSelectedDate"
    />
    <label class="mt-3 settings-title">
      {{ $t('components.form.marketing.campaign.target') }}
    </label>
    <b-row class="col-form-label-sm">
      <b-col v-if="custom !== 'custom'" align="left" cols="6">
        <d-button
            text="components.form.marketing.campaign.all-customers"
            class="d-btn-sm btn d-btn-load-document font-text-title"
            :class="customSentOption ? '' : 'd-btn-load-document-selected'"
            @on:button-click="$emit('on:custom-sent-option:update', false)"
        />
      </b-col>
      <b-col :align="custom !== 'custom' ? 'left' : 'center'" :cols="custom !== 'custom' ? 6 : 12">
        <d-button
            text="components.form.marketing.campaign.one-customer"
            class="d-btn-sm btn d-btn-load-document font-text-title"
            :class="customSentOption ? 'd-btn-load-document-selected' : ''"
            @on:button-click="$emit('on:custom-sent-option:update', true)"
        />
      </b-col>
    </b-row>
    <div v-if="customSentOption" class="mt-3">
      <recipients-list
          :custom="custom"
          :customer="customer"
          :tags="recipientList"
      />
    </div>
    <div class="mt-3" v-else>
      <activities-list
          :tags="activityList"
          :activities="emailCampaign.filters ? emailCampaign.filters.find(el => el.key === 'activity').values : []"
      />
      <subscriptions-list
          class="mt-2"
          :tags="subscriptionPlanList"
          :subscriptions="emailCampaign.filters ? emailCampaign.filters.find(el => el.key === 'subscriptionPlan').values : []"
      />
      <groups-list
          class="mt-2"
          :tags="groupList"
          :groups="emailCampaign.filters ? emailCampaign.filters.find(el => el.key === 'group').values : []"
      />
    </div>
  </div>
</template>
<script>
import 'vue2-datepicker/index.css';
import {arrayMap} from "@/utils/classes";
import EmailCampaign from "@/classes/doinsport/EmailCampaign";

export default {
  data: () => ({
    sentDate: false,
    onDeep: true,
    selectedDate: new Date(),
    validation: require('@validation/entities/doinsport/EmailCampaign.json'),
    selectedHour: {
      HH: '00',
      mm: '00'
    },
    displayCalendar: false,
  }),
  props: {
    emailCampaign: {
      type: Object,
      default: () => {
      },
    },
    isSmsCampaign: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => {
      },
    },
    recipientList: {
      type: Array,
      default: () => [],
    },
    activityList: {
      type: Array,
      default: () => [],
    },
    subscriptionPlanList: {
      type: Array,
      default: () => [],
    },
    groupList: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'create',
    },
    custom: {
      type: String,
      default: 'default'
    },
    customSentOption: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    emailCampaign: {
      deep: true,
      handler() {
        if (this.emailCampaign.id !== null && this.onDeep) {
          this.onDeep = false;
          this.initForm();
        }
      }
    },
    customSentOption: function () {
      if (this.customSentOption) {
        this.emailCampaign.clients = [];
      } else {
        this.emailCampaign.filters = new EmailCampaign().filters;
      }
      this.activityList = [];
      this.subscriptionPlanList = [];
      this.groupList = [];
    },
    sentDate: function () {
      if (this.sentDate === false) {
        this.emailCampaign.programmingDate = null;
      }
    },
    recipientList: {
      deep: true,
      handler() {
        let list = this.recipientList;
        this.emailCampaign.clients = arrayMap(list, (_, obj) => obj.code);
      }
    },
    activityList: {
      deep: true,
      handler() {
        let list = this.activityList;
        if ("undefined" !== typeof this.emailCampaign.filters && this.emailCampaign.filters) {
          this.emailCampaign.filters.find(el => el.key === 'activity').values = arrayMap(list, (_, obj) => obj.code);
        }
      }
    },
    subscriptionPlanList: {
      deep: true,
      handler() {
        let list = this.subscriptionPlanList;

        if ("undefined" !== typeof this.emailCampaign.filters && this.emailCampaign.filters)  {
          this.emailCampaign.filters.find(el => el.key === 'subscriptionPlan').values = arrayMap(list, (_, obj) => obj.code);
        }
      }
    },
    groupList: {
      deep: true,
      handler() {
        let list = this.groupList;
        if ("undefined" !== typeof this.emailCampaign.filters && this.emailCampaign.filters)  {
          this.emailCampaign.filters.find(el => el.key === 'group').values = arrayMap(list, (_, obj) => obj.code);
        }
      }
    },
    selectedHour: {
      deep: true,
      handler() {
        this.setProgrammingDate();
      }
    }
  },
  components: {
    RecipientsList: () => import('@custom/marketing/campaign/RecipientsList'),
    ActivitiesList: () => import('@custom/marketing/campaign/ActivitiesList'),
    SubscriptionsList: () => import('@custom/marketing/campaign/SubscriptionsList'),
    GroupsList: () => import('@custom/marketing/campaign/GroupsList'),
    SwitchBtn: () => import ('vue-switches'),
  },
  computed: {
    selectedDateFormat () {
      return this.$moment(this.selectedDate).format('DD/MM/YYYY');
    },
    fromDate () {
      return  this.$moment().add('-1', 'days');
    }
  },
  methods: {
    onSelectedDate(selected) {
      this.selectedDate = selected;
      this.setProgrammingDate();
    },
    setProgrammingDate() {
      const dateFormat = this.$moment(this.selectedDate);
      const timeFormat = this.$moment(this.selectedHour.HH + ':' + this.selectedHour.mm, 'HH:mm');

      dateFormat.set({
        hour: timeFormat.get('hour'),
        minute: timeFormat.get('minute'),
        second: timeFormat.get('second')
      });

      this.emailCampaign.programmingDate = dateFormat;
    },
    initForm() {
      if (this.emailCampaign.id) {
        // init datepicker & timepicker
        if (this.emailCampaign.programmingDate === null) {
          this.sentDate = false;
        } else {
          this.sentDate = true;
          this.selectedHour.HH = this.$moment(this.emailCampaign.programmingDate).format('HH');
          this.selectedHour.mm = this.$moment(this.emailCampaign.programmingDate).format('mm');
          this.selectedDate = this.emailCampaign.programmingDate;
        }
      }
    }
  },
}
</script>
<style lang="scss" src="@lazy/_time-picker.scss" scoped/>
